<template>
  <div class="index">
    <router-link to="/wechatReport">wechatReport</router-link> |
    <router-link to="/wechatNirvana">wechatNirvana</router-link> |
    <router-link to="/wechatReportPerson">wechatReportPerson</router-link> |
    <router-link to="/wechatActivity">wechatActivity</router-link> |
    <router-link to="/wechatCzActivity">wechatCzActivity</router-link> |
    <router-link to="/wechatPk">wechatPk</router-link> |
    <router-link to="/pc">pc</router-link> |
    <router-link to="/clear">clear</router-link> |
    <router-link to="/test">test</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
</style>